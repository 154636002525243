@import '../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

/* Settings styles */
html,
body {
  margin: 0;
  height: 100vh;
}

#root {
  overflow-x: hidden;
}

.mainPage {
  text-align: center;
  margin-top: 75px;
}

.docFrame {
  display: grid;
  width: 100vw;
  min-height: 100vh;
  grid-template-columns: 320px 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas: "aside main";
}

// page structure
main {
  grid-area: main;
  padding-bottom: $spacing-xxl;
}

aside {
  grid-area: aside;
  color: $white;

  .header {
    text-align: center;
  }

  .navigation__nav {
    background-color: white;
    position: fixed;
    padding-top: 25px;
    top: 60px;
    bottom: 0;
    left: 0;
    padding-bottom: $spacing-xxl;
    width: 320px;
  }

  ul {
    padding: 0;
  }

  li,
  li > a,
  li label,
  li > a:focus,
  li > a:active,
  li > a:visited {
    color: #16171a;
    cursor: pointer;
  }

  li > a,
  li label {
    display: block;
    padding: 6px $spacing-xl;

    &:hover {
      background-color: #2b6bdd;
      color: white;
    }
  }

  li a.active {
    background-color: #2b6bdd;
    color: white;
    position: relative;

    &::before {
      content: "";
      width: 4px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }

  .navigation--expand {
    margin-bottom: 0;
    padding-left: 16px;
    font-size: 14px;
  }

  // sub-menu items
  .navigation__submenu {
    position: relative;

    input[type="checkbox"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
    }

    input[type="checkbox"]:checked {
      & ~ .navigation__arrow-right {
        display: none !important;
      }

      & ~ .navigation__arrow-down {
        display: inline-block !important;
      }

      & ~ ul {
        display: block;
      }
    }

    svg {
      position: absolute;
      right: $spacing-l;
      top: $spacing-xs;
      pointer-events: none;
    }

    svg.navigation__arrow-down {
      display: none !important;
    }

    ul {
      display: none;
    }

    ul a {
      padding: $spacing-xs $spacing-xl $spacing-xs 15px;
      height: 32px;
    }

    .navigation__submenu {
      label {
        padding: $spacing-xs $spacing-xl $spacing-xs 15px;
        height: 32px;
      }

      ul a {
        padding: $spacing-xs $spacing-xl $spacing-xs 15px;
        height: 32px;
      }
    }
  }

  .navigation__filter {
    margin: $spacing-s $spacing-xl $spacing-s $spacing-xl;

    input {
      background-color: rgba(255, 255, 255, 0.1);
      border: none;
      color: $white;
    }
  }
}

.navigation__button {
  display: none;
}

%center-docs {
  width: 850px;
  margin: 0 auto;
}

.anchor {
  & .anchor-link {
    padding-left: 8px;
    display: none;
  }

  & h3:hover,
  & h4:hover {
    & .anchor-link {
      display: inline;
    }
  }
}

.anchor::before {
  display: block;
  height: 16px;
  margin-top: -16px;
  visibility: hidden;
  content: "";
}

.props {
  width: 100%;
}

code {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.navigation__filter {
  margin-top: 24px;
}

.docs__header {
  width: 100%;
  border-radius: 0;
  position: fixed;
  z-index: 1000;
}

.docs__navigation {
  padding-bottom: 1em;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  overflow: auto;
}

.docs__content {
  padding-top: 50px;
}

.toggle-example {
  &__button {
    margin: 0 8px 8px 0;
  }

  &__checkbox {
    margin: 0;
    display: inline-block;
    width: 200px;
  }

  &__row {
    margin-bottom: 16px;

    & .radio-inline {
      width: 200px;

      & + .radio-inline {
        margin: 0;
      }
    }
  }

  &__slider {
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .docFrame {
    grid-template-columns: 100%;
    grid-template-areas: "main";
  }

  .navigation__button {
    position: absolute;
    display: block;
    top: $spacing-l;
    left: $spacing-l;

    a {
      display: inline-flex;
      align-items: center;
      color: $white;

      &:hover {
        opacity: $opacity-hover;
      }
    }

    &.active {
      left: 284px;
      z-index: 1;
      transition: none;
    }
  }

  aside {
    display: absolute;
    transition: 0.25s;

    &.active {
      grid-area: unset;
      z-index: 3;
      display: block;

      .navigation {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: 320px;
        box-shadow: inset -10px 0 5px -5px hsla(0, 0%, 0%, 0.25);
      }

      .navigation__nav {
        box-shadow: inset -10px 0 5px -5px hsla(0, 0%, 0%, 0.25);
      }

      & ~ main {
        transform: translateX(320px);
        transition: 0.25s;
      }
    }
  }
}

.main-header {
  width: 100%;
  height: 57px;
  position: fixed;
  z-index: 999;
  background-color: white;
  line-height: 24px;
  padding: 15px 15px;
  margin: 0;
  border-bottom: 1px solid #cacfd9;
}

.error-message {
  padding-top: 75px;
  text-align: center;
}

#header {
  padding-top: 75px;
  font-size: 16px;
  line-height: 45px;
  font-weight: 500;
}

#header-no-padding {
  font-size: 16px;
  line-height: 45px;
  font-weight: 500;
}

#section-header {
  font-size: 14px;
  line-height: 40px;
}

#TableExpandableRowTableSearchInput {
  width: 50%;
}

.ant-pagination-item-ellipsis {
  display: none;
}

i.anticon.anticon-double-left.ant-pagination-item-link-icon,
i.anticon.anticon-double-right.ant-pagination-item-link-icon {
  display: none;
}

.boolean-display {
  display: block !important;
}

.switch{border:1px solid #ccc;width:50px;height:26px;border-radius:13px;cursor:pointer;display:inline-block}.switch-toggle{border:1px solid #999;box-shadow:1px 1px 1px #ccc;width:25px;height:24px;left:0;border-radius:12px;background:#fff;position:relative;transition:left .2s ease-in-out}.switch.on{background:#2b6bdd}.switch.on .switch-toggle{left:23px}.switch.disabled{cursor:not-allowed}
